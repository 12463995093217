import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import TableCell from '@mui/material/TableCell';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FilterHeader from '../../ui-component/FilterHeader/FilterHeader';
import React from 'react';
import {
  creditNoteManagemnetSelector,
  updateCreditNoteCreator,
  getRequestsForCNCreator,
  markFlagCreator,
  updateRequestsForCNCreator,
  fileDownloadCreator,
} from '../../redux';
import { appSelector } from '@app/store/selectors';
import moment from 'moment';
import CheckApplication from '../CheckApplication';
import CloseIcon from '@mui/icons-material/Close';
import CustomGrid from '../../ui-component/CustomGrid';
import theme from '@app/themes';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './index.css';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import { editSupplyCreator } from '../../redux/slice';
import { sizePxToEm } from '@app/common/constants';
import { ADMIN_TOASTER_MESSAGE } from '@app/screens/admin-configuration/utils/adminConstant';

const styles = {
  tableCell: { padding: 0, borderRadius: 10 },
  tableCellEmpty: { borderBottom: 'none' },
  tableCellWidth100: { borderBottom: 'none', padding: 0, width: '100%' },
  container: {
    height: '50vh',
    width: '100%',
    padding: 3,
    borderRadius: 0,
    border: 0,
    borderBottom: 'none',
  },
  gridStyle: {
    '& .text-align-center': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      color: '#494761',
      fontWeight: '400',
      fontStyle: 'Poppins',
      textAlign: 'left',
      fontSize: 12,
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      borderRadius: 2,
    },
    '& .boldHeader': {
      width: 46.7,
      height: 15.3,
      fontSize: 10.7,
      fontWeight: '400',
      fontStyle: 'Poppins',
      lineHeight: 16,
      letterSpacing: '0.21%',
      textAlign: 'left',
      color: '#322B7C',
    },
    '&>.MuiDataGrid-root': {
      border: 'none',
    },
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '90%',
    //height: '90%',
    backgroundColor: '#ffffff',
    //paddingTop: 5,
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        width: '100%',
        height: '100%',
        backgroundColor: '#322B7C',
        borderRadius: '100px',
        top: '0.2px',
        zIndex: 1,
        marginLeft: '1px',
        marginRight: '1px',
      },
      root: {
        minHeight: 'unset',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: '#322B7C',
        zIndex: 3,
        padding: '7px 20px',
        minWidth: 'unset',
        minHeight: 'unset',
        '&.Mui-selected': {
          color: '#FFFFFF',
        },
      },
    },
  },
};

const getTextTitle = (item: any, currentUserRole: string) => {
  let str = ''
  if (item?.futureRequestStatus == 'Rejected') {
    str = 'Rejected';
  }
  else {
    if (item?.isApplicationApproved) {
      str = 'Application Approved';
    } else {
      str = 'Prior Approval pending';
    }
  }
  if (item?.role && currentUserRole && currentUserRole != item?.role) {
    str = `${str} by ${item?.role}`
  }
  return str
};

const getTextColor = (item: any) => {
  if (item?.futureRequestStatus == 'Rejected') {
    return theme.colors.red[300];
  }
  else {
    if (item?.isApplicationApproved) {
      return '#18A685';
    } else {
      return '#F5AD00';
    }
  }
};

const getAction = (item: any) => {
  if (item?.isActionedByCurrentUser || item?.isApplicationApproved) {
    return 'Review'
  }
  else {
    return 'Check Application';
  }
};

const getRequestStatus = (item: any) => {
  if (item?.futureRequestStatus == 'Rejected') {
    if (!item?.isApplicationApproved) {
      return 'Application Rejected';
    }
  }
  if (!item?.isClaimSubmitted) {
    // Application
    if (item?.isApplicationApproved) {
      return '';
    } else {
      return 'Application Approval';
    }
  } else {
    return '';
  }
};

const showEditIcon = (color: any = '#322B7C') => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_67_314)">
          <path d="M3.33333 14.3987C2.98009 14.3975 2.64171 14.2565 2.39193 14.0068C2.14214 13.757 2.00123 13.4186 2 13.0654V3.73203C2.00106 3.37873 2.14178 3.04028 2.3916 2.79046C2.64142 2.54064 2.98004 2.39975 3.33333 2.39869H9.33333V3.73203H3.33333V13.0654H12.6667V7.06536H14V13.0654C13.9989 13.4187 13.8582 13.7571 13.6084 14.0069C13.3586 14.2567 13.02 14.3976 12.6667 14.3987H3.33333ZM7.33333 9.06536V7.65195L11.4401 3.54534L12.8532 4.95875L8.74675 9.06536H7.33333ZM11.9141 3.07203L12.3939 2.59205C12.5188 2.46809 12.6876 2.39853 12.8636 2.39853C13.0396 2.39853 13.2085 2.46809 13.3333 2.59205L13.8066 3.06471C13.9308 3.18962 14.0007 3.35864 14.0007 3.53476C14.0007 3.71088 13.9308 3.87974 13.8066 4.00465L13.3268 4.48463L11.9141 3.07203Z"
            fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_67_314">
            <rect width="16" height="16" fill="white" transform="translate(0 0.400024)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

const showFlagIcon = (color: any = '#322B7C') => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_76_1234)">
          <path d="M4.30143 15.0667C4.12462 15.0667 3.9551 14.9964 3.83008 14.8714C3.70505 14.7464 3.63477 14.5768 3.63477 14.4C3.63477 14.2232 3.70505 14.0537 3.83008 13.9287C3.9551 13.8036 4.12462 13.7334 4.30143 13.7334H4.9681V2.40003C4.9681 2.22322 5.03839 2.0537 5.16341 1.92867C5.28844 1.80365 5.45795 1.73336 5.63477 1.73336C5.81158 1.73336 5.9811 1.80365 6.10612 1.92867C6.23114 2.0537 6.30143 2.22322 6.30143 2.40003V13.7334H6.9681C7.14491 13.7334 7.31443 13.8036 7.43945 13.9287C7.56448 14.0537 7.63477 14.2232 7.63477 14.4C7.63477 14.5768 7.56448 14.7464 7.43945 14.8714C7.31443 14.9964 7.14491 15.0667 6.9681 15.0667H4.30143ZM6.9681 2.40003L12.9681 6.40003L6.9681 10.4V2.40003Z"
            fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_76_1234">
            <rect width="16" height="16" fill="white" transform="translate(0.300781 0.400024)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}


//Approve request default component
const ApproveRequest = () => {
  const dispatch = useDispatch();
  const [selectedTab, setTabValue] = useState(0);
  const [selectedTabFilter, setTabFilterValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedrequestData, setrequestData] = useState('');
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminusergroup = useSelector(
    creditNoteManagemnetSelector.getadminusergroup(),
    shallowEqual,
  );

  const [modalType, setModalType] = useState<null | 'editSupply' | 'flag' | 'requestApproval'>(null);
  const [modalData, setModalData] = useState<any>();
  const [otherModalData, setotherModalData] = useState<any>();
  const [requestTableCol, setrequestTableCol] = useState<any>([]);
  const [requestTableData, setrequestTableData] = useState<any>([]);
  const [isViewSupplyBills, setisViewSupplyBills] = useState<any>(false);

  const [stateClearFilter, setclearFilter] = useState(false);

  const [pageNo, setpageNo] = useState<number>(1);

  const [appliedFilter, setappliedFilter] = useState<any>(null);

  const screenLoader = useSelector(creditNoteManagemnetSelector.getscreenLoader(), shallowEqual);


  //define constant
  const isCNUser = adminusergroup?.adminGroup == 'CN' ? true : false;
  const isPMTUser = adminusergroup?.adminGroup == 'PMT' ? true : false;
  const isFCUser = adminusergroup?.adminGroup == 'FC' ? true : false;

  //clear filter Data
  const clearFilterHandler = () => {
    setappliedFilter(null)
    if (stateClearFilter) {
      setclearFilter(false);
    } else {
      setclearFilter(true);
    }
  }

  //APi Call
  const creditNote = useSelector(
    creditNoteManagemnetSelector.getcreditNote(),
    shallowEqual,
  );
  const cnRequests = useSelector(
    creditNoteManagemnetSelector.getcnRequests(),
    shallowEqual,
  )

  const loadRequestData = () => {
    if (isCNUser) {
      dispatch(
        getRequestsForCNCreator({
          requestTypeId: selectedTab == 1 ? 1 : selectedTab == 0 ? 2 : null,
          pageNumber: pageNo,
          rowsPerPage: 1000,
          staffPositionId: userInfo?.staffPositionId, //9812,
          isApproved: selectedTabFilter ? true : false,
          ///...payload,
          ...appliedFilter
        }),
      );
    } else {
      dispatch(
        updateCreditNoteCreator({
          requestTypeId: selectedTab == 1 ? 1 : selectedTab == 0 ? 2 : null,
          statusId:
            selectedTabFilter == 1 ? 2 : selectedTabFilter == 2 ? 1 : null,
          pageNumber: pageNo || 1,
          ///...payload,
          ...appliedFilter
        }),
      );
    }
  };

  //call first time
  useEffect(() => {
    loadRequestData()
  }, [])

  //call when data change
  useEffect(() => {
    console.log('appliedFilter, selectedTabFilter, selectedTab---', appliedFilter, selectedTabFilter, selectedTab)
    loadRequestData()
  }, [appliedFilter, selectedTabFilter, selectedTab
  ])

  useEffect(() => {
    console.log('---selectedTab--', selectedTab)
  }, [selectedTab])

  useEffect(() => {
    if (adminusergroup?.adminGroup) {
      console.log('--staffPositionId-', adminusergroup?.adminGroup)
      loadRequestData()
    }
  }, [adminusergroup?.adminGroup])

  const appliedFilterHandler = (filterData: any) => {
    const { fromDate, toDate, Hq, state, mr, claimNo, division } = filterData;
    const managefilterData = {
      fromDate: fromDate ? moment(fromDate).format('MM-DD-YYYY') : null,
      toDate: toDate ? moment(toDate).format('MM-DD-YYYY') : null,
      claimNo: claimNo || null,
      hqIds: Hq,
      stateIds: state,
      divisionIds: division,
      mrIds: mr
    };
    setappliedFilter(managefilterData)
  };

  //CreditNote/ ZHO tab handler
  const handleTabChange = (_event: any, newValue: number): void => {
    clearFilterHandler()
    setTabValue(newValue);
    setTabFilterValue(0);
  };

  //CN claim submission and approve tab change handler
  const handleTabFilterChange = (_event: any, newValue: number): void => {
    clearFilterHandler()
    setTabFilterValue(newValue);
  };

  // Handler for Set User Data to a table
  useEffect(() => {
    if (cnRequests || creditNote) {
      if (isCNUser) {
        cnDataHandler(cnRequests)
      } else {
        otherUserSetData(creditNote)
      }
    }
  }, [cnRequests, creditNote]);

  //CN column/data set

  //Tooltip
  const renderTooltipItem = (text: any) => {
    return (text && text?.length > 0) ? <Tooltip title={text}>
      <span>{text}</span>
    </Tooltip> : (text || '')
  }

  const getKeyValuesfromData = (data: any[], keyName: any) => {
    return renderTooltipItem(data?.map((e: any) => e?.[keyName])?.join(", "))
  }

  const cnDataHandler = (dataProps: any) => {
    const cnTeamHeader = [
      {
        field: 'requestNumber',
        headerName: 'Claim No.',
        pinned: 'left',
        cellStyle: { textAlign: selectedTabFilter === 1 ? 'center' : 'left' },
        minWidth: selectedTabFilter === 1 ? 100 : 170,
        //showDisabledCheckboxes: true,
        checkboxSelection: true
        // checkboxSelection: selectedTabFilter === 1 ? false : function (params: any) {
        //   return params?.data?.isFlagged ? false : true;
        // }
      },
      {
        field: 'cnNumber', headerName: 'CN No.', maxWidth: 120,
        cellRenderer: (params: any) => {
          return params?.data?.cnNumber ? params?.data?.cnNumber : ''
        },
      },
      {
        field: 'approvedDate', headerName: 'Approved Date',
        cellRenderer: (params: any) => {
          return params?.data?.approvedDate ? moment(params?.data?.approvedDate).format("DD/MM/YYYY") : ''
        },
        maxWidth: 120
      },
      { field: 'approvedAmount', headerName: 'Approved Amount', maxWidth: 120,
        cellRenderer: (params: any) => Number(params?.data?.approvedAmount).toFixed(2) || '',
      },
      { field: 'stockistName', headerName: 'Stockist', tooltipField: 'stockistName' },
      { field: 'userStation', headerName: 'Station' },
      { field: 'doctorName', headerName: 'Doctor' },
      {
        field: 'retailerName', headerName: 'Retailer', maxWidth: 100,
        cellRenderer: (params: any) => {
          return renderTooltipItem(params?.data?.retailerName || '')
        },
      },
      {
        //field: 'prescriptionFileName',
        headerName: 'Dr. Prescription',
        cellRenderer: (params: any) =>
          <>
            {params?.data?.prescriptionFilePath &&
              <span onClick={() => downloadPrescription(params?.data?.prescriptionFilePath)} style={{ color: '#322B7C', fontSize: 10.7, fontWeight: 500, cursor: 'pointer', textDecoration: 'underline' }}>Prescription</span>}
          </>,
        maxWidth: 120
      },
      {
        field: '2', headerName: 'Material Desc.', maxWidth: 150,
        cellRenderer: (params: any) => renderTooltipItem(params?.data?.requestClaimProductBatches?.[0]?.productDescription ||
          params?.data?.requestClaimProductBatches?.[0]?.productName
          || ''),

      },
      {
        headerName: 'BATCH NO',
        cellRenderer: (params: any) => params?.data?.requestClaimProductBatches?.[0]?.batch || '',
        width: 100
      },
      {
        field: '4', headerName: 'Official Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.officialQty).toFixed(2) || '',
        maxWidth: 100
      },
      {
        field: '5', headerName: 'Official Bonus Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.officialBonusQty).toFixed(2) || '',
        maxWidth: 115,
        minWidth: 115
      },
      {
        field: 'approvedAmount', headerName: 'Approved Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.approvedQty).toFixed(2) || '',
        maxWidth: 100
      },
      {
        field: '6', headerName: 'Approved Bonus Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.approvedBonusQty).toFixed(2) || '',
        maxWidth: 130,
        minWidth: 130
      },
      {
        field: '7', headerName: 'Supply Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.supplyQty).toFixed(2) || '',
        maxWidth: 100
      },
      {
        field: '8', headerName: 'Supply Bonus Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.bonus).toFixed(2) || '',
        maxWidth: 120,
        minWidth: 120
      },
      {
        field: '8', headerName: 'Difference Qty.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.differenceQty).toFixed(2) || '',
        maxWidth: 100
      },
      {
        field: '9', headerName: 'Extra %age.',
        cellRenderer: (params: any) => Number(params?.data?.requestClaimProductBatches?.[0]?.extraPercentageAge).toFixed(2) || '0.00',
        maxWidth: 100
      },
      {
        field: '10', headerName: 'Purchase No.',
        cellRenderer: (params: any) => getKeyValuesfromData(params?.data?.requestClaimProductBatches, 'purchaseNo'),
        maxWidth: 120
      },
      {
        field: '11', headerName: 'Purchase Date',
        cellRenderer: (params: any) => moment(params?.data?.requestClaimProductBatches?.[0]?.purchaseDate).format('DD/MM/YYYY') || '',
        maxWidth: 120
      },
      {
        field: '12', headerName: 'Supply Bill No.',
        cellRenderer: (params: any) => getKeyValuesfromData(params?.data?.requestClaimProductBatches, 'billNumber'),
        maxWidth: 120
      },
      {
        field: '13', headerName: 'Supply Bill Date',
        cellRenderer: (params: any) => moment(params?.data?.requestClaimProductBatches?.[0]?.billDate).format('DD/MM/YYYY') || '',
        maxWidth: 120
      },
      {
        field: '14',
        headerName: 'Attachment',
        cellRenderer: (params: any) => (
          <span style={{ display: 'flex' }}>
            <VisibilityIcon
              onClick={() => {
                setisViewSupplyBills(true);
                setrequestData(params?.data);
                setModalOpen(true);
              }}
              style={{ cursor: 'pointer', color: '#322B7C', marginRight: 20, marginTop: 10, width: 20, height: 20 }}
            />
          </span>
        ),
        maxWidth: 120
      },
      {
        headerName: 'MR Remarks',
        cellRenderer: (params: any) => <span style={{ width: 'fit-content' }}>{renderTooltipItem(getRemark(params, 'MR'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        headerName: 'FLM Remarks',
        cellRenderer: (params: any) => <span>{renderTooltipItem(getRemark(params, 'FLM'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        headerName: 'SLM Remarks',
        cellRenderer: (params: any) => <span>{renderTooltipItem(getRemark(params, 'SLM'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        headerName: 'TLM Remarks',
        cellRenderer: (params: any) => <span>{renderTooltipItem(getRemark(params, 'TLM'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        headerName: 'HO Remarks',
        cellRenderer: (params: any) => <span>{renderTooltipItem(getRemark(params, 'HO'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        headerName: 'CN Remarks',
        cellRenderer: (params: any) => <span>{renderTooltipItem(getRemark(params, 'CN'))}</span>,
        maxWidth: 220,
        width: 120
      },
      {
        field: 'actionedItems',
        headerName: '',
        pinned: 'right',
        className: `hide-scrollbar`,
        minWidth: 80,
        cellRenderer: (params: any) => (
          <span style={{}}>
            <span
              style={{
                cursor: 'pointer',
              }}
              onClick={() =>
                modalDataSetHandler(params, 'editSupply')
              }
            >
              {showEditIcon(params?.data?.isClaimCompleted ? '#322B7C' : '#322B7C',)}
            </span>
            <span
              style={{
                cursor: 'pointer',
                marginLeft: 10
              }}
              onClick={() =>
                modalDataSetHandler(params, 'flag')
              }
            >   {showFlagIcon(params?.data?.isFlagged ? 'red' : '#322B7C')}
            </span>
          </span>
        )
      },
    ];
    let filterCNHeader = cnTeamHeader
    if (selectedTabFilter === 0) {
      filterCNHeader = cnTeamHeader.filter((e: any) => !['cnNumber', 'approvedDate', 'approvedAmount'].includes(e?.field))
    }
    if (selectedTabFilter === 1) {
      filterCNHeader = cnTeamHeader.filter((e: any) => !['actionedItems'].includes(e?.field))
      let dat = cnTeamHeader.filter((e: any) => e?.field == 'requestNumber')?.[0];
      dat.checkboxSelection = false
    }
    setrequestTableCol(filterCNHeader);
    if (dataProps && dataProps?.data) {
      setrequestTableData(dataProps?.data);
    }
  };

  //FCM user Handler/column set
  const otherUserSetData = (dataProps?: any) => {
    setrequestTableCol
      ([
        {
          field: 'requestNumber',
          headerName: 'Claim No.',
          headerClassName: 'boldHeader',
          sortable: false,
          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'requestDate',
          headerName: 'Date',
          headerClassName: 'boldHeader',
          sortable: false,
          cellRenderer: (params: any) => {
            const cellValue = params.valueFormatted
              ? params.valueFormatted
              : params.value;
            return params && moment(cellValue).format('DD/MM/YYYY');
          },
          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'stockistName',
          headerName: 'Stockist',
          headerClassName: 'boldHeader',
          sortable: false,
          //maxWidth: 180,
          minWidth: 150,
        },
        {
          field: 'userStation',
          headerName: 'Station',
          headerClassName: 'boldHeader',
          sortable: false,
          maxWidth: 70,
          minWidth: 70,
        },
        {
          headerClassName: 'boldHeader',
          headerName: 'Status',
          sortable: false,
          cellRenderer: (params: any) => {
            const cellValue = params?.data || {};
            return (
              <span style={{ color: getTextColor(cellValue) }}>
                {getTextTitle(cellValue, adminusergroup?.adminGroup)}
              </span>
            );
          },
          // maxWidth: 150,
          minWidth: 170,
        },
        // {
        //   headerClassName: 'boldHeader',
        //   headerName: 'Request type',
        //   sortable: false,
        //   cellRenderer: (params: any) => {
        //     const cellValue = params?.data || {};
        //     return getRequestStatus(cellValue);
        //   },
        //   maxWidth: 120,
        //   minWidth: 120,
        // },
        {
          headerClassName: 'boldHeader',
          headerName: 'Sales Value (in ₹)',
          sortable: false,
          cellRenderer: (params: any) => {
            return params?.data?.salesValue ? Number(params?.data?.salesValue).toFixed(2) : 0;
          },
          maxWidth: 120,
          minWidth: 120,
        },
        {
          headerClassName: 'boldHeader',
          headerName: 'Actions',
          sortable: false,
          minWidth: 140,
          maxWidth: 140,
          cellRenderer: (params: any) => {
            return (
              <span
                onClick={() => {
                  setrequestData(params?.data);
                  setModalOpen(true);
                }}
                style={{
                  color: '#322b7c',
                  fontWeight: 400,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <DescriptionIcon />
                {
                  getAction(params.data)
                }
              </span>
            );
          },
        },
      ]);
    setrequestTableData(dataProps?.data);
  }

  const modalDataSetHandler = (params: any, modalType: null | 'editSupply' | 'flag' | 'requestApproval', othersModalDataParam?: any) => {
    setotherModalData(params);
    setModalData(params);
    setotherModalData(othersModalDataParam)
    if (modalType) {
      setModalType(modalType);
    } else {
      setModalType(null)
    }
  };

  const markedAll = (dataProps: any, isActive: boolean, selectedData: any) => {
    if (dataProps.length && dataProps.length != 0) {
      if (
        selectedData?.filter((e: any) => e?.isFlagged == true)?.length >
        0
      ) {
        return ToasterService.showToaster(
          `We found some of the item\'s is marked as flagged. Kindly remove flag to ${isActive ? 'approve' : 'reject'} them`,
          ToastType.WARNING,
        );
      }
      modalDataSetHandler([], 'requestApproval',
        {
          requestIds: dataProps,
          status: isActive ? 1 : 0,
        })
    }
  };

  const getRemark = (params: any, type: string) => {
    const remarks: any = params?.data?.remarks || null;
    return (
      (remarks?.map &&
        remarks?.filter((e: any) => e?.remarkBy == type)?.[0]?.remark) ||
      ''
    );
  };

  const downloadPrescription = (filePath: string) => {
    dispatch(
      fileDownloadCreator({
        fileName: filePath,
        isType: 'prescription'
      }))
  }

  const onUpdateModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      loadRequestData()
    }, 1000)
  };

  return (
    <div className='approveContainer'>
      <div style={{ padding: '0px 22px 0px 22px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className='creditNoteTabs'>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={'Credit Note Requests'} value={0} />
            <Tab label={'ZHO Requests'} value={1} />
          </Tabs>
        </div>
        {isCNUser ?
          <div style={{ paddingBottom: 10 }}>
            <Tabs
              value={selectedTabFilter}
              onChange={handleTabFilterChange}
              sx={{ border: 2, borderRadius: 20, backgroundColor: '#FFFFFF' }}
              className="roundedTab"
            >
              <Tab label={'Claim Submission'} value={0} style={{ fontSize: 14 }} />
              <Tab label={'Approved'} value={1} />
            </Tabs>
          </div> :
          <div style={{ paddingBottom: 10 }}>
            {(isPMTUser || isFCUser) ? <Tabs
              value={selectedTabFilter}
              onChange={handleTabFilterChange}
              sx={{ border: 2, borderRadius: 20, backgroundColor: '#FFFFFF' }}
              className="roundedTab"
            >
              <Tab label={'All'} value={0} style={{ fontSize: 14 }} />
              <Tab label={'Prior Approval'} value={1} />
              <Tab label={'Approved'} value={2} />
            </Tabs> : null}
          </div>}
      </div>
      {(isPMTUser || isFCUser || isCNUser) && <FilterHeader
        clickFilterHandler={(data: any) => appliedFilterHandler(data)}
        clearFilterClick={stateClearFilter}
        filterType={{
          isToDate: true,
          isFromDate: true,
          isClaim: [isFCUser, isPMTUser].includes(true),
          isMr: [isFCUser, isPMTUser, isCNUser].includes(true),
          isState: [isFCUser, isPMTUser, isCNUser].includes(true),
          isHQ: [isFCUser, isPMTUser, isCNUser].includes(true),
          isDivision: [isPMTUser, isCNUser].includes(true),
        }}
      />}
      {!isCNUser && !screenLoader && (
        <Box sx={[styles.gridStyle, styles.container]}>
          <CustomGrid columnDefs={requestTableCol} rowData={requestTableData} />
        </Box>
      )}
      {isCNUser && !screenLoader && (
        <div style={{ width: '100%', height: '50vh', padding: 20 }} className='cnUserGrid'>
          {
            <CustomGrid
              columnDefs={requestTableCol}
              rowData={requestTableData}
              rejectHandler={(dataIds: any, selectedData: any) =>
                markedAll(dataIds, false, selectedData)
              }
              approvedHandler={(dataIds: any, selectedData: any) =>
                markedAll(dataIds, true, selectedData)
              }
              userType="CN"
              isHeader={selectedTabFilter === 1 ? false : true}
            />
          }
        </div>
      )}
      <Modal
        open={modalType != null && (modalData || otherModalData)}
        onClose={() => {
          setModalType(null);
          setModalData(null);
          setotherModalData(null)
        }}
      >
        <Box sx={[styles.modalStyle]}
          style={{
            width: sizePxToEm(406.7), height: sizePxToEm(382), borderRadius: 13
          }}
        >
          <div style={{ position: 'fixed', right: 15, top: 25 }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setModalType(null);
                setModalData(null);
                setotherModalData(null)
              }}
              style={{ color: '#322B7C', width: 32, height: 32 }}
            >
              <CloseIcon style={{ fontSize: 35 }} />
            </IconButton>
          </div>
          <ModalContent
            modalType={modalType}
            modalData={modalData}
            otherModalData={otherModalData}
            closeModal={(isDataUpdate?: boolean) => {
              setModalType(null);
              setModalData(null);
              setotherModalData(null)
              if (isDataUpdate) {
                setTimeout(() => {
                  loadRequestData()
                }, 1000)
              }
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={isModalOpen && (selectedrequestData ? true : false)}
        onClose={() => setModalOpen(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: isViewSupplyBills ? 'fit-content' : '90%',
            backgroundColor: '#ffffff',
            overflow: 'auto',
          }}
          style={{
            borderRadius: 12,
            paddingTop: 25
          }}
          className={`hide-scrollbar`}
        >
          <div style={{ position: 'fixed', right: 10, top: 20 }}>
            <IconButton aria-label="delete" onClick={() => setModalOpen(false)}>
              <CloseIcon style={{ fontSize: 25 }} />
            </IconButton>
          </div>
          <CheckApplication
            selectedData={selectedrequestData}
            onCloseHandler={() => onUpdateModal()}
            isViewSupplyBills={isViewSupplyBills}
          />
        </Box>
      </Modal>
    </div>
  );
};

const ModalContent = (props: any) => {
  const dispatch = useDispatch();
  const { modalType, modalData, otherModalData } = props;
  const [remarkText, setremarkText] = useState('');
  const [supplyQ, setsupplyQ] = useState<any>(null);
  const [supplyBQ, setsupplyBQ] = useState<any>(null);

  // edit supply api call
  const EditSupply = () => {
    dispatch(
      editSupplyCreator({
        id: Number(modalData?.data?.requestClaimProductBatches?.[0]?.requestClaimBillDetailId),
        supplyQty: Number(supplyQ),
        bonus: Number(supplyBQ)
      })
    )
    props?.closeModal && props?.closeModal(true);
  }

  //set supply bills data
  useEffect(() => {
    if (modalType == 'editSupply' && props?.modalData?.data?.requestClaimProductBatches?.[0]) {
      let supplyData = props?.modalData?.data?.requestClaimProductBatches?.[0];
      setsupplyQ(supplyData?.supplyQty);
      setsupplyBQ(supplyData?.bonus);
    }
  }, [props?.data?.data])

  // mark falg api call
  const MarkFlag = (isFlagged: any) => {
    if (String(remarkText).trim()?.length <= 0) {
      return ToasterService.showToaster(ADMIN_TOASTER_MESSAGE.ENTER_REMARK, ToastType.WARNING);
    }
    dispatch(
      markFlagCreator({
        requestId: modalData?.data?.requestId,
        remark: remarkText,
        isFlagged: isFlagged
      }),
    );
    props?.closeModal && props?.closeModal(true);
  }

  const ApprovalAll = () => {
    if (String(remarkText).trim()?.length <= 0) {
      return ToasterService.showToaster(ADMIN_TOASTER_MESSAGE.ENTER_REMARK, ToastType.WARNING);
    }
    dispatch(
      updateRequestsForCNCreator({
        requestIds: otherModalData?.requestIds,
        remarks: remarkText,
        status: otherModalData?.status ? 1 : 3,
      }),
    );
    props?.closeModal && props?.closeModal(true);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: sizePxToEm(353.3),
        alignContent: 'center',
        marginLeft: sizePxToEm(26),
        marginTop: 30
      }}
    >
      <Typography
        sx={{
          fontSize: 22.7,
          fontWeight: '400',
          color: '#1B1938',
          marginBottom: 2,
        }}
      >
        {modalType == 'editSupply' && 'Edit'}
        {modalType == 'flag' && 'Flag Remarks'}
        {modalType == 'requestApproval' && 'Provide your remarks'}
      </Typography>
      {(modalType == 'flag' || modalType == 'requestApproval') && (
        <TextField
          id="outlined-multiline-static"
          label="Remark"
          multiline
          rows={4}
          value={remarkText}
          onChange={e => setremarkText(e.target.value)}
        />
      )}
      {modalType == 'editSupply' && (
        <div style={{ marginTop: 10, position: 'relative' }}>
          <div className='editPopupLabel'>Supply Quantity</div>
          <TextField
            style={{ background: 'white', width: '100%' }}
            placeholder="Supply Quantity"
            type="number"
            value={supplyQ}
            onChange={(e: any) => setsupplyQ(e?.target?.value)}
            sx={{
              label: {
                marginTop: -0.5,
                paddingLeft: 0.5,
                fontSize: sizePxToEm(16),
                color: '#1C193980',
                fontFamily: 'Poppins',
                fontWeight: 400,
                opacity: 1
              },
            }}
          />
          <div style={{ marginTop: 30 }} />
          <div className='editPopupLabel'>Supply Bonus Quantity</div>
          <TextField
            style={{ background: 'white', width: '100%' }}
            placeholder="Supply Bonus Quantity"
            type="number"
            value={supplyBQ}
            onChange={(e: any) => setsupplyBQ(e?.target?.value)}
            sx={{
              label: {
                marginTop: -0.5,
                paddingLeft: 0.5,
                fontSize: sizePxToEm(16),
                color: '#1C193980',
                fontFamily: 'Poppins',
                fontWeight: 400,
                opacity: 1
              },
            }}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          marginTop: sizePxToEm(modalType == 'flag' ? 70 : 50),
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          onClick={() => { setremarkText(''); setsupplyBQ(''); setsupplyQ('') }}
          style={{ minWidth: 165, width: 165 }}
        >
          Reset
        </Button>
        {modalType == 'requestApproval' &&
          <Button variant="contained" onClick={() => ApprovalAll()} style={{ minWidth: 165 }}>
            {otherModalData?.status == 1 ? `${otherModalData?.requestIds?.length > 1 ? 'Approve All' : 'Approve'}` : `${otherModalData?.requestIds?.length > 1 ? 'Reject All' : 'Reject'}`}
          </Button>
        }
        {modalType == 'editSupply' &&
          <Button variant="contained" onClick={() => EditSupply()} style={{ minWidth: 165 }}
          >
            Save
          </Button>
        }
        {modalType == 'flag' &&
          <Button variant="contained" onClick={() => MarkFlag(modalData?.data?.isFlagged)} style={{ minWidth: 165 }}>
            {`${modalData?.data?.isFlagged ? 'Remove flag' : 'Mark Flag'}`}
          </Button>
        }
      </div>
    </div>
  );
};


export default ApproveRequest;
