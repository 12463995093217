import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import "./index.css"
import moment from "moment";
import Male from '@app/assets/images/male.png';
import FeMale from '@app/assets/images/female.png';
import { useDispatch } from "react-redux";
import { fileDownloadCreator } from "../redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@app/assets/svg/DownloadIcon.svg';

const CustomTable = (columns: any, rows: any, className: any = {}, onUpdate?: any, minWidth: number = 600) => {
    const dispatch = useDispatch();

    const fileDownload = (filePath: string) => {
        dispatch(
            fileDownloadCreator({
                fileName: filePath
            }))
    }

    return (
        <Table sx={{ minWidth: minWidth }} aria-label="simple table" style={{ tableLayout: 'fixed' }}>
            <TableHead>
                <TableRow style={{ width: '100%' }}>
                    {columns && columns?.map((col: any) => {
                        return <>
                            <TableCell style={{ textTransform: 'capitalize', background: col?.backgroundColor ?? 'transparent', width: col?.width }} className={`${className?.headerCellItem || ''} ${col?.headerCellClass ?? ''}`} >
                                {col?.name}
                                {col?.type == 'subheader' &&
                                    <Table className="subRowTableHeader" style={{ tableLayout: 'fixed' }} >
                                        <TableRow >
                                            {col && col?.subHeaderColumn?.map((col1: any) =>
                                                <TableCell style={{ textTransform: 'capitalize', width: col?.width }} className="subTableHeader"> {col1?.name}</TableCell>
                                            )}
                                        </TableRow>
                                    </Table>
                                }
                            </TableCell>
                        </>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows?.map && rows?.map((row: any, key: any) => {
                    return (
                        <>
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className={`${className?.tableRow} ${row?.rowAdditionalClass}`}
                            >
                                {columns?.map((col: any) =>
                                    <TableCell style={{ background: `${col?.backgroundColor || ''}` }} scope="row" className={`${className?.tableRowCell}`}>
                                        {col?.type == 'showActive' ?
                                            <Typography className={className?.rowCellItem} sx={{ fontSize: 12.7, fontWeight: '500', textAlign: 'center', color: ['Approved', true].includes(row?.[col?.key]) ? '#18A685' : row?.[col?.key] == 'Pending' ? '#F5AD00' : row?.[col?.key] == 'Rejected' ? 'red' : row?.[col?.key] == false ? '#ACACAC' : 'black' }}>
                                                {(col?.activeText && col?.inActiveText) ?
                                                    row?.[col?.key] == true ? col?.activeText : row?.[col?.key] == false ? col?.inActiveText : row?.[col?.key]
                                                    : row?.[col?.key]}
                                            </Typography>
                                            :
                                            col?.type == 'date' ?
                                                <Typography className={className?.rowCellItem} sx={{ fontSize: 16.7, fontWeight: '600', color: '#322B7C' }}>
                                                    {moment(row?.[col?.key])?.format('DD/MM/YYYY')}
                                                </Typography>
                                                :
                                                col?.type == 'link' ?
                                                    <a onClick={() => fileDownload(row?.[col?.linkAddress])} href={"javascript:void(0)"} >{row?.[col?.key]}</a>
                                                    :
                                                    col?.type == 'radioButton' ?
                                                        <Stack key={`radio_${key}`} direction="row" spacing={1} alignItems="center" alignSelf={'flex-end'}>
                                                            <Typography variant="switch" style={{ fontSize: 16 }}>InActive</Typography>
                                                            <Switch key={`radio_${key}1`}
                                                                sx={{
                                                                    "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
                                                                        transform: 'translateX(16px)',
                                                                    },
                                                                    "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                                        opacity: 1,
                                                                    }
                                                                }} defaultChecked={row?.[col?.key]} inputProps={{ 'aria-label': 'ant design' }} onChange={(evt) => onUpdate && onUpdate('radioButtonUpdate', evt, row)} />
                                                            <Typography className={className?.rowCellItem} variant="switch" style={{ fontSize: 16 }}>Active</Typography>
                                                        </Stack>
                                                        :
                                                        col?.type == 'containImg' ?
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                                                                {row?.doctorGender == 'M' ?
                                                                    <img src={Male} alt='Image' style={{ width: 26, height: 26, marginRight: 5 }} /> :
                                                                    row?.doctorGender == 'F' ?
                                                                        <img src={FeMale} alt='Image' style={{ width: 26, height: 26, marginRight: 5 }} /> : ''
                                                                }
                                                                <span className={`${className?.rowCellItem}`} style={{ textAlign: 'left' }}>
                                                                    {row?.[col?.key]}
                                                                </span>
                                                            </div> :
                                                            col?.type == 'subheader' ?
                                                                <Table sx={{ minWidth: 200, tableLayout: 'fixed' }} aria-label="simple table" className="subRowTable">
                                                                    <TableRow style={{ boxShadow: 'none' }} className="subRowTableRow">
                                                                        {col?.subHeaderColumn?.map((col1: any, key: any) => <TableCell style={{ minWidth: '33%', maxWidth: '33%' }} scope="row" >
                                                                            {row?.[col?.key]?.[col1?.key] || ''}{(col1?.postText && row?.[col?.key]?.[col1?.key]) && `${col1?.postText}`}
                                                                        </TableCell>
                                                                        )}</TableRow>
                                                                </Table> :
                                                                col?.type == 'downloadWithIcon' ?
                                                                    <span>
                                                                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <VisibilityIcon
                                                                                onClick={() => fileDownload(row?.[col?.linkAddress])}
                                                                                style={{ cursor: 'pointer', color: '#322B7C', marginRight: 20, marginTop: 5, width: 20, height: 20 }}
                                                                            />
                                                                            <img
                                                                                onClick={() => fileDownload(row?.[col?.linkAddress])}
                                                                                src={DownloadIcon}
                                                                                style={{ cursor: 'pointer' }}
                                                                            ></img>
                                                                        </span>
                                                                    </span>
                                                                    : <Typography className={`${className?.rowCellItem} ${col?.cellClass ?? ''} `} sx={{ fontSize: 16.7, fontWeight: '600', color: '#322B7C' }} style={{textAlign: 'left', display: 'flex'}}>
                                                                        {row?.[col?.key] || ''}
                                                                    </Typography>
                                        }
                                        {(col?.postText && row?.[col?.key]) && ` ${col?.postText}`}
                                    </TableCell>)}
                            </TableRow>
                            {/* {row?.compatitorProducts?.map((subProductData: any) =>
                            <>
                                <tr className={row?.rowAdditionalClass}>
                                    {
                                        columns?.map((col: any, subKey: any) => {
                                            return <td className="rowCellItem" style={{ paddingLeft: 16 }}>
                                                {`
                                        ${subKey == 0 ? `competitor product : ${subProductData?.competitorProduct}` :
                                                        subKey == 1 ? `${subProductData?.additionalRequirement}` :
                                                            subKey == 2 ? `${''}` :
                                                                subKey == 3 ? `${subProductData?.monthlyConsumption}` :
                                                                    subKey == 4 ? `${subProductData?.bonusSchemeQty}` :
                                                                        subKey == 5 ? `${subProductData?.companyName}` :
                                                                            ''
                                                    }
                                        `}
                                            </td>
                                        })
                                    }
                                </tr>
                            </>)} */}
                            {row?.rowAdditionalClass && <div style={{ height: 8 }} />}
                        </>
                    )
                })}
            </TableBody >
        </Table >
    )
}
export default CustomTable;