import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Box, Button, InputLabel, TextField } from '@mui/material';
import DatePickerComponent from '@app/components/date-picker/DatePickerComponent';
import Dropdown from '@app/components/dropdown';
import { Styles } from './style';
import { MRListCreator, creditNoteManagemnetSelector, divisionListCreator, statehqlistCreator, statehqlistWatcher, stockListCreator, updateSummaryDashboardCreator } from '../../redux';
import { appSelector } from '@app/store/selectors';
import moment from 'moment';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import './style.css';
import { makeStyles } from '@mui/styles';
import { MultiSelect } from "react-multi-select-component";

function FilterHeader(props: { clickFilterHandler: Function, clearFilterClick: any, filterType: any, isHeaderStyle?: any, multipleType?: any }) {
  const dispatch = useDispatch();
  const statehqList = useSelector(creditNoteManagemnetSelector.getstatehqlist(), shallowEqual);
  const MrList = useSelector(creditNoteManagemnetSelector.getMRList(), shallowEqual);
  const stockList = useSelector(creditNoteManagemnetSelector.getstockList(), shallowEqual);
  const divisionList = useSelector(creditNoteManagemnetSelector.getdivisionList(), shallowEqual);
  const screenLoader = useSelector(creditNoteManagemnetSelector.getscreenLoader(), shallowEqual);

  const { isHeaderStyle = true } = props;
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);

  const onChangeClaimNumber = (event: any) => {
    setselectedClaimNo(event?.target?.value)
  }


  // useEffect(() => {
  //   dispatch(
  //     statehqlistCreator({}));
  //   dispatch(
  //     MRListCreator({
  //       staffPositionId: 12905
  //     }));
  //   dispatch(
  //     stockListCreator({
  //       staffPositionId: 12905
  //     }));
  //   dispatch(
  //     divisionListCreator({}));
  // }, [userInfo])

  const [selectedFromDate, setselectedFromDate] = useState<any>(null);
  const [selectedToDate, setselectedToDate] = useState<any>(null);
  const [selectedClaimNo, setselectedClaimNo] = useState<any>(null);
  const [selectedHq, setselectedHq] = useState<any>([]);
  const [selectedState, setselectedState] = useState<any>([]);
  const [selectedMR, setselectedMR] = useState<any>([]);
  const [selectedStock, setselectedStock] = useState<any>([]);
  const [selectedDivision, setselectedDivision] = useState<any>([]);

  useEffect(() => {
    setselectedFromDate(null);
    setselectedToDate(null);
    setselectedClaimNo('');
    setselectedHq([]);
    setselectedState([]);
    setselectedMR([]);
    setselectedStock([])
    setselectedDivision([])
  }, [props?.clearFilterClick])

  const useStyles = makeStyles(() => ({
    popupIndicator: {
      color: '#1C1939',
    },
    customTextField: {
      "& input::placeholder": {
        fontSize: 12.7,
        color: '#1C1939',
        fontFamily: 'Poppins',
        fontWeight: 400,
        opacity: 1
      }
    }
  }));

  const filterListFormat = (filterValue: any) => {
    if (filterValue?.length) {
      if (filterValue?.length > 0) return filterValue?.map((e: any) => e?.value);
      else[]
    } else {
      if (filterValue?.value) { return [filterValue?.value] } else { return [] };
    }
  }

  const classes = useStyles();

  if (selectedDivision.length > 1 && !props?.multipleType?.isDivision) {
    setselectedDivision(selectedDivision.slice(1))
  }
  if (selectedState.length > 1 && !props?.multipleType?.isState) {
    setselectedState(selectedState.slice(1))
  }
  if (selectedHq.length > 1 && !props?.multipleType?.isHQ) {
    setselectedHq(selectedHq.slice(1))
  }
  if (selectedMR.length > 1 && !props?.multipleType?.isMr) {
    setselectedMR(selectedMR.slice(1))
  }
  if (selectedStock.length > 1 && !props?.multipleType?.isStock) {
    setselectedStock(selectedStock.slice(1))
  }



  return (
    <div className={isHeaderStyle ? 'filterStyledContainer filterComponent' : ' filterComponent'}>
      <div className="myflex filterContainer" >
        {props?.filterType?.isFromDate &&
          <div className="dateBox" style={Styles.inputCell} data-testid={'selectDate-picker'}>
            {!isHeaderStyle && <div className='summaryFilterLabel'>FROM DATE</div>}
            <DatePickerComponent
              disablePast={false}
              onError={() => null}
              value={selectedFromDate}
              placeholder={"Select From Date"}
              isHeaderStyle={true}
              onChange={(val: Date) => {
                if (moment(val) > moment(selectedToDate)) {
                  ToasterService.showToaster(
                    'From date cannot be greater than To date.',
                    ToastType.ERROR,
                  );
                } else {
                  setselectedFromDate(val)
                }
              }}
            />
          </div>}
        {props?.filterType?.isToDate &&
          <div className="dateBox" style={Styles.inputCell} data-testid={'selectDate-picker'}>
            {!isHeaderStyle && <div className='summaryFilterLabel'>TO DATE</div>}
            <DatePickerComponent
              disablePast={false}
              onError={() => null}
              value={selectedToDate}
              placeholder={"Select To Date"}
              isHeaderStyle={true}
              onChange={(val: Date) => {
                if (moment(val) < moment(selectedFromDate)) {
                  ToasterService.showToaster(
                    'To date cannot be less than From date',
                    ToastType.ERROR,
                  );
                } else {
                  setselectedToDate(val)
                }
              }}
            />
          </div>}

        {props?.filterType?.isClaim && <div className="boxItem" style={Styles.inputCell}>
          <TextField
            style={{ background: 'white', width: '100%' }}
            placeholder="Claim No."
            type="text"
            value={selectedClaimNo}
            onChange={onChangeClaimNumber}
            classes={{ root: classes.customTextField }}
            sx={{
              svg: {
                color: '#322b7c',
              },
              label: {
                marginTop: -0.5,
                paddingLeft: 0.5,
                fontSize: 12.7,
                color: '#1C1939',
                fontFamily: 'Poppins',
                fontWeight: 400,
                opacity: 1
              },
            }}
          />
        </div>}

        {props?.filterType?.isDivision && <div className="boxItem" style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          {!isHeaderStyle && <div className='summaryFilterLabel'>Division</div>}
          <MultiSelect
            options={divisionList?.map((e: any) => ({ ...e, label: e?.name, value: e?.id })) || []}
            value={selectedDivision || { label: 'dd', value: null }}
            onChange={(val: any) => setselectedDivision(val)}
            labelledBy={"Select Division"}
            hasSelectAll={false}
            overrideStrings={{ selectSomeItems: 'Select Division', }}
            className={`${!props?.multipleType?.isDivision ? 'singleFilter' : ''}`}
            disableSearch
            closeOnChangedValue={props?.multipleType?.isDivision ? false : true}
          />
        </div>}

        {props?.filterType?.isState && <div className="boxItem" style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          <MultiSelect
            options={statehqList?.stateList?.map((e: any) => ({ ...e, label: e?.name, value: e?.id })) || []}
            value={selectedState}
            onChange={(val: any) => setselectedState(val)}
            labelledBy="Select State"
            hasSelectAll={false}
            overrideStrings={{ selectSomeItems: 'Select State', }}
            className={`${!props?.multipleType?.isState ? 'singleFilter' : ''}`}
            disableSearch
            closeOnChangedValue={props?.multipleType?.isState ? false : true}
          />
        </div>}

        {props?.filterType?.isHQ && <div className="boxItem" style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          {!isHeaderStyle && <div className='summaryFilterLabel'>HQ</div>}
          <MultiSelect
            options={statehqList?.headQuators?.map((e: any) => ({ ...e, label: e?.headQuatorsName, value: e?.headQuatorsId })) || []}
            value={selectedHq}
            onChange={(val: any) => setselectedHq(val)}
            labelledBy="Select HQ"
            hasSelectAll={false}
            overrideStrings={{ selectSomeItems: props?.multipleType?.isHQ ? 'Select Multiple' : 'Select HQ', }}
            className={`${!props?.multipleType?.isHQ ? 'singleFilter' : ''}`}
            disableSearch
            closeOnChangedValue={props?.multipleType?.isHQ ? false : true}
          />
        </div>}

        {props?.filterType?.isMr && <div className="boxItem" style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          {!isHeaderStyle && <div className='summaryFilterLabel'>MR</div>}
          <MultiSelect
            options={MrList?.map((e: any) => ({ ...e, label: e?.name, value: e?.id })) || []}
            value={selectedMR}
            onChange={(val: any) => setselectedMR(val)}
            labelledBy="Select MR"
            hasSelectAll={false}
            overrideStrings={{ selectSomeItems: props?.multipleType?.isMr ? 'Select Multiple' : 'Select MR', }}
            className={`${!props?.multipleType?.isMr ? 'singleFilter' : ''}`}
            disableSearch
            closeOnChangedValue={props?.multipleType?.isMr ? false : true}
          />
        </div>}
        {props?.filterType?.isStock && <div className="boxItem" style={Styles.inputCell} data-testid={'selectDiv-dropdown'}>
          {!isHeaderStyle && <div className='summaryFilterLabel'>Stockist</div>}
          <MultiSelect
            options={stockList?.map((e: any) => ({ ...e, label: e?.name, value: e?.id })) || []}
            value={selectedStock}
            onChange={(val: any) => setselectedStock(val)}
            labelledBy="Select Stockist"
            hasSelectAll={false}
            overrideStrings={{ selectSomeItems: props?.multipleType?.isStock ? 'Select Multiple' : 'Select Stockist', }}
            className={`${!props?.multipleType?.isStock ? 'singleFilter' : ''}`}
            disableSearch
            closeOnChangedValue={props?.multipleType?.isStock ? false : true}
          />
        </div>}
        <div className={`${!isHeaderStyle ? 'goButton' : ''}`}
        >
          {!isHeaderStyle && <div style={{ visibility: 'hidden' }} className='summaryFilterLabel'>_</div>}
          <Button
            data-testid="clickGo-icon"
            variant="contained"
            onClick={() => {
              props?.clickFilterHandler && props?.clickFilterHandler({
                fromDate: selectedFromDate,
                toDate: selectedToDate,
                Hq: filterListFormat(selectedHq),
                state: filterListFormat(selectedState),
                mr: filterListFormat(selectedMR),
                claimNo: selectedClaimNo,
                stock: filterListFormat(selectedStock),
                division: filterListFormat(selectedDivision)
              })
            }}
            size="small"
            style={{
              fontFamily: ['Poppins'].join(','),
              fontSize: 12.7,
              padding: '11px',
              // fontWeight: '600',
              cursor: 'pointer',
              // fontStyle: 'normal',
              letterSpacing: 0,
              textAlign: 'center',
              backgroundColor: '#322b7c',
              color: 'white',
              border: 0,
              borderRadius: 10,
              minWidth: 50,
              marginLeft: 6
            }}>
            Go
          </Button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FilterHeader);
